<template>
  
  <div id="app" class="flex-container">
    <main class="flex-shrink-0">

    <!-- Main content area where routed components will be rendered -->
    <router-view />
    
  </main>
    <!-- Include the footer component -->
    <AppFooter />
  </div>
</template>

<script>
// Import the AppFooter component
import AppFooter from '@/components/app/AppFooter.vue'; 

export default {
  name: 'App',
  components: {
    AppFooter
  }
}
</script>

<style>
.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* optional */
}
</style>