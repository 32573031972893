<template>
    <div>
      <!-- Header -->
      <header class="py-5">
        <div class="container px-6 pb-5">
          <router-link to="/">
            <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
          </router-link>
          <!-- Navigation -->
          <AppHeader />
        </div>
        <div class="container px-5">
          <div class="row gx-5 align-items-center text-center">
            <h1 class="lh-1 mb-3" style="font-weight: 800;">Lidmaatschap</h1>
            <p class="lead fw-normal text-muted mb-5">
              Hier kun je jouw lidmaatschapsvoordelen en status controleren.
            </p>
          </div>
        </div>
      </header>
      <section class="bg-body py-5">
        <div class="container mb-5">
          <div class="row justify-content-center">
            <div class="col-lg-3 mb-4">
              <!-- Include Account Navigation -->
              <AccountNavigation :currentPath="currentPath" />
            </div>
            <div class="col-lg-7">
              <!-- Membership Info Component -->
              <MembershipInfo />
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import AppHeader from '@/components/app/AppHeader.vue';
  import AccountNavigation from '@/components/account/nav/AccountNavigation.vue';
  import MembershipInfo from '@/components/account/membership/MembershipInfo.vue';
  
  export default {
    components: {
      AppHeader,
      AccountNavigation,
      MembershipInfo // Register the MembershipInfo component
    },
    setup() {
      const currentPath = 'account/lidmaatschap'; // Set the current path for the navigation
  
      return {
        currentPath
      };
    }
  }
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  </style>
  