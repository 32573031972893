<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <router-link to="/">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
                </router-link>
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Lidmaatschap</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Krijg toegang tot betrouwbare tech-ondersteuning.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="    margin-top: -121px;">
                <!-- 121 px instead of -97 px because g-4 represents 24 px -->
                <div class="row row-cols-1 row-cols-lg-3 g-4">
                    <div class="col d-flex">
                        <div class="card  lg-card-blue h-100 d-flex flex-column justify-content-between"
                            style="padding: 25px 0px; border-radius: 25px;">
                            <div class="card-body align-items-center text-center">
                                <img src="https://cdn.pcforge.nl/assets/1.0/images/ondemand.png" width="64">
                                <p></p>
                                <h3 class="card-title">On Demand</h3>
                                <h6 class="card-subtitle mb-2"
                                    style="color: white; font-size: 25px; font-weight: bold;"> Géén abonnementskosten
                                </h6>
                                <span>Betaal per technische dienst of advies. Ideaal voor klanten die specifieke,
                                    eenmalige hulp nodig hebben zonder een doorlopend lidmaatschap.</span>
                                <ul class="text-center" style="list-style-type: none;">
                                    <br>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Betaal</b> per dienst</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Brede</b> technische diensten</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Geen</b> abonnementskosten</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-red">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Transparante</b> prijsstelling</span>
                                    </li>
                                    <br>
                                </ul>
                            </div>
                            <div class="align-items-center text-center">
                                <button type="button" class="btn btn-light btn-lg">
                                    <i class="fa-solid fa-circle-arrow-right" aria-hidden="true"></i> Vraag advies
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex">
                        <div class="card  lg-card-orange h-100 d-flex flex-column justify-content-between"
                            style="padding: 25px 0px; border-radius: 25px;">
                            <div class="card-body align-items-center text-center">
                                <img src="https://cdn.pcforge.nl/assets/1.0/images/basis.png" width="64">
                                <p></p>
                                <h3 class="card-title">Basis <span class="badge bg-light"
                                        style="font-size: 15px; color: #333;">Meest gekozen</span></h3>
                                <h6 class="card-subtitle mb-2"
                                    style="color: white; font-size: 25px; font-weight: bold;"> €9,99 / maand </h6>
                                <span>Basis advies en technische ondersteuning met toegang tot verschillende gidsen en
                                    FAQ's. Korting op extra diensten. Ideaal voor incidentele hulp.</span>
                                <ul class="text-center" style="list-style-type: none;">
                                    <br>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Basis</b> ondersteuning en advies</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Kennisbank</b> toegang</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>1x</b> Computer APK per jaar</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-orange">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>1x</b> Systeemoptimalisatie per jaar</span>
                                    </li>
                                    <br>
                                </ul>
                            </div>
                            <div class="align-items-center text-center">
                                <a href="https://pcforge.nl/account/lidmaatschap?aanvragen"><button type="button"
                                        class="btn btn-light btn-lg">
                                        <i class="fa-solid fa-circle-arrow-right" aria-hidden="true"></i> Lid worden
                                    </button></a>
                            </div>
                        </div>
                    </div>
                    <div class="col d-flex">
                        <div class="card lg-card-green h-100 d-flex flex-column justify-content-between"
                            style="padding: 25px 0px; border-radius: 25px;">
                            <div class="card-body align-items-center text-center">
                                <img src="https://cdn.pcforge.nl/assets/1.0/images/plus.png" width="64">
                                <p></p>
                                <h3 class="card-title">Plus</h3>
                                <h6 class="card-subtitle mb-2"
                                    style="color: white; font-size: 25px; font-weight: bold;"> €24,99 / maand </h6>
                                <span>Onbeperkt en gepersonaliseerd advies met prioriteitsklantensupport. Korting op
                                    extra diensten. Ideaal voor regelmatige, diepgaande ondersteuning.</span>
                                <ul class="text-center" style="list-style-type: none;">
                                    <br>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Uitgebreide</b> ondersteuning en advies</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>Korting</b> op onze diensten</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-blue">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>3x</b> Computer APK per jaar</span>
                                    </li>
                                    <li class="check-li">
                                        <span class="checkmark-orange">
                                            <i class="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        <span class="text"><b>2x</b> Systeemoptimalisatie per jaar</span>
                                    </li>
                                    <br>
                                </ul>
                            </div>
                            <div class="align-items-center text-center">
                                <a href="https://pcforge.nl/account/lidmaatschap?aanvragen"><button type="button"
                                        class="btn btn-light btn-lg">
                                        <i class="fa-solid fa-circle-arrow-right" aria-hidden="true"></i> Lid worden
                                    </button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br>
                <div class="row gx-5 align-items-center text-center">
                    <h2 class="display-6 lh-1" style="font-weight: bold;">Veelgestelde vragen</h2>
                    <span class="lead fw-normal text-muted mb-5 mb-lg-4"> Als je geen antwoord op je vraag ziet, kan je
                        contact met ons opnemen via ons <a
                            href="https://pcforge.nl/contact/">contactformulier</a>.</span>
                    <br>
                    <br>
                    <div class="col-md-7 mx-auto">
                        <div id="accordionExample" class="accordion">
                            <div class="accordion-item">
                                <h2 id="headingOne" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne" class="accordion-button">
                                        <b>Wat is het On Demand lidmaatschap?</b>
                                    </button>
                                </h2>
                                <div id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse show">
                                    <div class="accordion-body">Het On Demand lidmaatschap betekent dat je gebruik kunt
                                        maken van onze technische diensten en advies zonder een doorlopend lidmaatschap
                                        of abonnementskosten.
                                        Je betaalt alleen voor de specifieke diensten die je nodig hebt, zonder enige
                                        verplichtingen voor een maandelijkse bijdrage. Dit model is ideaal voor klanten
                                        die af en toe hulp nodig hebben en geen
                                        behoefte hebben aan een vast lidmaatschap.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingTwo" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                        aria-expanded="false" aria-controls="collapseTwo"
                                        class="accordion-button collapsed">
                                        <b>Wat is standaard inbegrepen in alle lidmaatschappen?</b>
                                    </button>
                                </h2>
                                <div id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">Bij al onze lidmaatschappen krijg je toegang tot
                                        deskundige ondersteuning van ervaren technici, die altijd klaarstaan om je te
                                        helpen met advies en oplossingen.
                                        We garanderen transparante prijsstelling zonder verborgen kosten, zodat je
                                        precies weet waar je aan toe bent.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingFour" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                        aria-expanded="false" aria-controls="collapseThree"
                                        class="accordion-button collapsed">
                                        <b>Hoe kan ik extra diensten aanvragen?</b>
                                    </button>
                                </h2>
                                <div id="collapseThree" aria-labelledby="headingFour" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">Je kunt extra diensten eenvoudig aanvragen via je
                                        persoonlijke pcforge account. Het Plus-lidmaatschap biedt als voordeel dat je
                                        een korting ontvangt op deze extra diensten. Alleen Plus-leden komen in
                                        aanmerking voor deze gereduceerde tarieven.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                        aria-expanded="false" aria-controls="collapseFour"
                                        class="accordion-button collapsed">
                                        <b>Hoe werkt de korting op aanvullende diensten bij het Plus lidmaatschap?</b>
                                    </button>
                                </h2>
                                <div id="collapseFour" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">Bij het Plus lidmaatschap ontvang je een korting van 20%
                                        op extra technische diensten die je buiten de reguliere dekking van je
                                        lidmaatschap afneemt.
                                        Dit betekent dat je bij het Plus lidmaatschap profiteert van een gereduceerd
                                        tarief voor aanvullende diensten zoals extra computer APK’s,
                                        systeemoptimalisaties, en andere specialistische ondersteuning.
                                        De korting wordt automatisch toegepast op de kosten van deze extra diensten,
                                        waardoor je kosten bespaart en meer waar voor je geld krijgt.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive"
                                        aria-expanded="false" aria-controls="collapseFive"
                                        class="accordion-button collapsed">
                                        <b>Kan ik het On Demand lidmaatschap combineren met Basis of Plus?</b>
                                    </button>
                                </h2>
                                <div id="collapseFive" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">Het On Demand-lidmaatschap is bedoeld voor klanten die
                                        geen doorlopend lidmaatschap wensen.
                                        Met dit lidmaatschap kun je specifieke technische diensten en advies op aanvraag
                                        ontvangen zonder je vast te leggen aan een abonnement.
                                        Je betaalt enkel voor de diensten die je daadwerkelijk afneemt, zonder
                                        verplichtingen voor reguliere betalingen of lidmaatschapskosten.
                                        Dit biedt je de flexibiliteit om alleen te betalen voor de hulp die je nodig
                                        hebt, wanneer je het nodig hebt.</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 id="headingThree" class="accordion-header">
                                    <button type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix"
                                        aria-expanded="false" aria-controls="collapseSix"
                                        class="accordion-button collapsed">
                                        <b>Is er een limiet aan advies en ondersteuning?</b>
                                    </button>
                                </h2>
                                <div id="collapseSix" aria-labelledby="headingThree" data-bs-parent="#accordionExample"
                                    class="accordion-collapse collapse">
                                    <div class="accordion-body">
                                        Voor het On Demand-lidmaatschap is er geen limiet aan advies en ondersteuning,
                                        omdat je alleen betaalt voor de diensten die je op aanvraag ontvangt.<br><br>
                                        Het Basis-lidmaatschap biedt tot 3 sessies per maand voor advies en
                                        ondersteuning, wat op jaarbasis een aanzienlijk aantal oplevert. Het is dus
                                        onwaarschijnlijk dat je deze
                                        limiet elke maand of zelfs elk jaar zult bereiken. Mocht je echter meer hulp
                                        nodig hebben, dan bekijken wij dit graag op een case-to-case basis en kunnen
                                        wij, indien nodig,
                                        extra advies of ondersteuning kosteloos aanbieden.
                                        <br><br>
                                        Het Plus-lidmaatschap omvat tot 5 sessies per maand, wat ook een ruim aantal is
                                        op jaarbasis. Het is zeer onwaarschijnlijk dat je deze limiet per maand of jaar
                                        bereikt. Mocht je meer
                                        advies of ondersteuning nodig hebben, dan kunnen wij ook hier op een
                                        case-to-case basis aanvullende diensten gratis verlenen.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>