<template>
  <div>
    <!-- Header -->
    <header class="py-5">
      <div class="container px-6 pb-5">
        <router-link to="/">
          <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
        </router-link>
        <!-- Navigation -->
        <AppHeader />
        <div class="row gx-5 align-items-center">
          <div class="col-xxl-5">
            <!-- Header text content -->
            <div class="text-center text-xxl-start">
              <h1 class="display-3 fw-bolder mb-4">
                <span class="d-inline">pcforge.</span>
              </h1>
              <h5 class="mb-4">
                Vakkundig, betrouwbaar en snel. Daar staan wij voor. Of je nu jouw pc door ons wilt laten assembleren,
                advies wilt bij het uitzoeken van componenten of hulp nodig hebt bij jouw tech problemen.
              </h5>
              <div class="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xxl-start mb-3">
                <a class="btn btn-primary btn-lg px-5 py-3 me-sm-3 fs-6 fw-bolder" href="resume.html">
                  <i class="fas fa-handshake"></i> Onze diensten
                </a>
                <a class="btn btn-outline-dark btn-lg px-5 py-3 fs-6 fw-bolder" href="projects.html">
                  <i class="fa-solid fa-circle-right"></i> Hulp vragen
                </a>
              </div>
            </div>
          </div>
          <div class="col-xxl-7">
            <!-- Header profile picture -->
            <div class="d-flex justify-content-center mt-5 mt-xxl-0">
              <img class="img-fluid" src="https://cdn.pcforge.nl/assets/1.0/images/pc-pcforge.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- About Section -->
    <section class="bg-body py-5">
      <div class="container mb-5">
        <div class="row">
          <div class="col-md-4">
            <table class="table">
              <tbody>
                <tr style="border-color: #ff000000;">
                  <th style="border-top: unset;">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/fast-delivery.png" style="width: 60px;">
                  </th>
                  <th style="border-top: unset; vertical-align: middle;">
                    Snelle en efficiënte service
                    <br>
                    <span style="font-weight: 100;">Zodat jij snel aan de slag kunt</span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4">
            <table class="table">
              <tbody>
                <tr style="border-color: #ff000000;">
                  <th style="border-top: unset;">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/customer-satisfaction.png" style="width: 60px;">
                  </th>
                  <th style="border-top: unset; vertical-align: middle;">
                    Betrouwbaarheid en expertise
                    <br>
                    <span style="font-weight: 100;">Wij helpen je bij jouw problemen</span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-4">
            <table class="table">
              <tbody>
                <tr style="border-color: #ff000000;">
                  <th style="border-top: unset;">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/maatwerk.png" style="width: 60px;">
                  </th>
                  <th style="border-top: unset; vertical-align: middle;">
                    Maatwerk en kwaliteit
                    <br>
                    <span style="font-weight: 100;">Oplossingen op maat</span>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="card" style="background-color: #f9f9f9; padding: 20px; border-color: #f7f7f7;">
          <div class="card-body">
            <div class="container d-flex justify-content-center">
              <div class="row gx-5">
                <div class="col-xxl-8 mx-auto">
                  <div class="my-5 text-center">
                    <h2 class="display-5 fw-bolder">
                      <span class="text-gradient d-inline">Dé tech specialist</span>
                    </h2>
                    <p class="lead fw-light mb-4">Vakkundig, betrouwbaar en snel. Dat zijn onze kernwaarden.</p>
                    <p class="text-muted">
                      Bij pcforge streven we naar duurzaamheid en efficiëntie in elk aspect van onze dienstverlening. Of
                      het nu gaat om het assembleren van hoogwaardige pc's, het verstrekken van deskundig advies of het
                      bieden van betrouwbare reparaties.
                      <br><br>
                      Daarom zijn we dé specialist op het gebied van technologie. Wij richten ons op kwaliteit in plaats
                      van kwantiteit, en willen niet beperkt zijn tot computers alleen. Ons doel is om dé
                      tech-specialist te zijn voor al uw behoeften.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="container px-5">
              <div class="row row-cols-1 row-cols-lg-4 g-4">
                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/computer.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Desktop computers</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Assemblage</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Onderdelen overzetten</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/telefoon.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Smartphone</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Overzetten van gegevens</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Backup maken</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/windows.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Windows</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Herinstalleren</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Prestatieproblemen</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/macos.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">macOS</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Resetten van OS</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Gegevens overzetten</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/router-device.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Netwerk en routers</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Installeren van router</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Wi-Fi optimalisatie</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/smart-house.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Slimme apparaten</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Installeren en configuratie</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Oplossen van problemen</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/hdd.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Data herstel</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Verloren gegevens</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Back-up maken</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card card-grey h-100" style="padding: 5px 0px; border-radius: 25px;">
                    <div class="card-body d-flex flex-column align-items-center text-center">
                      <img src="https://cdn.pcforge.nl/assets/1.0/images/maintenance.png" class="mb-3" width="64">
                      <h5 style="font-weight: bold;">Onderhoud</h5>
                      <p>Je kunt bij ons terucht voor onder andere:</p>
                      <ul style="list-style: none;padding: 0;margin: 0;text-align: center;">
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Periodiek onderhoud</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-regular fa-circle-check" aria-hidden="true"></i>
                          <span>Virus- en malwarecontrole</span>
                        </li>
                        <li style="display: grid; grid-template-columns: 24px auto; gap: 10px; align-items: center;">
                          <i class="fa-solid fa-plus" aria-hidden="true"></i>
                          <span>En nog veel meer</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
  components: {
    AppHeader
  }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
