<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <router-link to="/">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
                </router-link>
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Diensten</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Vakkundig advies en ondersteuning voor uw technologie
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="margin-top: -97px;">
                <div class="row justify-content-center">



                </div>
            </div>
        </section>

    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
  components: {
    AppHeader
  },
  data() {
    // your data here
  }
}
</script>


<style scoped>
/* Add any component-specific styles here */
</style>