// src/session.js
import { reactive } from 'vue';

export const sessionData = reactive({
  isLoggedIn: false,
  userId: null,
  firstName: '',
  surName: '',
  fullName: '',
  gender: null,
  subscription: null, 
  subscriptionType: null,
  subscriptionDate: null,
  subscriptionExpiryDate: null
});

export async function destroySession() {
  try {
    const response = await fetch('https://api.pcforge.nl/v1/auth/user/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    const result = await response.json();

    if (result.status === 'success') {
      // Clear session data
      sessionData.isLoggedIn = false;
      sessionData.userId = null;
      sessionData.firstName = '';
      sessionData.surName = '';
      sessionData.fullName = '';
      sessionData.gender = null;
      sessionData.subscription = null;
      sessionData.subscriptionType = null;
      sessionData.subscriptionDate = null;
      sessionData.subscriptionExpiryDate = null;
    } else {
      console.error('Logout failed:', result.message);
    }
  } catch (error) {
    console.error('Error during logout:', error);
  }
}
