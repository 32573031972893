<template>
  <div class="card">
    <div class="card-body">
      <ul class="nav nav-pills flex-column">
        <li v-for="(item, path) in navItems" :key="path" class="nav-item">
          <!-- Handle non-disabled links -->
          <router-link
            v-if="!item.disabled && path !== 'auth/uitloggen'"
            :to="`/${path}`"
            class="nav-link"
            :class="{
              active: isActive(path),
              'disabled-link': item.disabled
            }"
            :aria-disabled="item.disabled ? 'true' : 'false'"
          >
            <i :class="`fa-solid ${item.icon}`"></i> &nbsp; {{ item.label }}
          </router-link>
          <!-- Handle disabled links -->
          <span
            v-else-if="item.disabled"
            class="nav-link disabled-link"
            :aria-disabled="item.disabled ? 'true' : 'false'"
          >
            <i :class="`fa-solid ${item.icon}`"></i> &nbsp; {{ item.label }}
          </span>
          <!-- Handle logout button -->
          <button
            v-else
            class="btn btn-link nav-link logout-button"
            @click.prevent="logout"
          >
            <i :class="`fa-solid ${item.icon}`"></i> &nbsp; {{ item.label }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { destroySession } from '@/session'; // Import the destroy function

export default {
  name: 'AccountNavigation',
  setup() {
    const router = useRouter();
    const loading = ref(false);

    // Define your paths, corresponding page names, and FontAwesome icons
    const navItems = {
      'account/overzicht': { label: 'Account overzicht', icon: 'fa-user' },
      'account/orders': { label: 'Orders', icon: 'fa-box-archive' },
      'account/lidmaatschap': { label: 'Lidmaatschap', icon: 'fa-id-card' },
      'account/persoonlijke-gegevens': { label: 'Jouw gegevens', icon: 'fa-gear' },
      'auth/uitloggen': { label: 'Uitloggen', icon: 'fa-right-from-bracket' }
    };

    // Get the current path
    const currentPath = computed(() => router.currentRoute.value.path.replace(/^\//, ''));

    // Determine if the path is active
    function isActive(path) {
      return currentPath.value === path;
    }

    // Handle logout
    async function logout() {
      try {
        loading.value = true;
        await destroySession(); // Call the session destroy function
        router.push("/"); // Redirect to home
      } catch (error) {
        console.error('Error during logout:', error);
      } finally {
        loading.value = false;
      }
    }

    return {
      navItems,
      isActive,
      logout
    };
  }
}
</script>

<style scoped>
.nav-link.active {
  font-weight: bold;
}

.disabled-link {
  color: #6c757d; /* Bootstrap's secondary color for disabled links */
  pointer-events: none; /* Disable click events */
}

.btn-link {
  color: #007bff; /* Bootstrap link color */
  cursor: pointer; /* Ensure the button looks clickable */
}

.btn-link:hover {
  color: #0056b3; /* Bootstrap link hover color */
  text-decoration: none; /* Remove underline on hover */
}
.logout-button {
  color: #6c757d; 
}
.logout-button:hover {
  color: #6c757d;
  text-decoration: none; 
}
</style>
