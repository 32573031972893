<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <router-link to="/">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
                </router-link>
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Algemene voorwaarden</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Duidelijke afspraken, transparante diensten.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="margin-top: -97px;">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-body">
                                <h2 style="font-weight: bold;">1. Definities</h2>
                                <p>1.1 In deze Algemene Voorwaarden wordt verstaan onder:</p>
                                <ul>
                                    <li><strong>pcforge</strong>: de onderneming die onder deze voorwaarden diensten en
                                        producten aanbiedt.</li>
                                    <li><strong>Klant</strong>: de natuurlijke persoon of rechtspersoon die met pcforge
                                        een overeenkomst aangaat voor het leveren van producten of diensten.</li>
                                    <li><strong>Diensten</strong>: alle diensten die door pcforge worden aangeboden
                                        zoals beschreven op de website en/of in andere communicatiemiddelen.</li>
                                    <li><strong>Overeenkomst</strong>: een overeenkomst tussen pcforge en de klant met
                                        betrekking tot de levering van diensten en/of producten.</li>
                                </ul>

                                <h2 style="font-weight: bold;">2. Toepasselijkheid</h2>
                                <p>2.1 Deze Algemene Voorwaarden zijn van toepassing op alle aanbiedingen, offertes,
                                    overeenkomsten en leveringen van diensten door pcforge, tenzij schriftelijk anders
                                    is overeengekomen.</p>
                                <p>2.2 Door het plaatsen van een bestelling of het aangaan van een overeenkomst
                                    verklaart de klant akkoord te gaan met deze Algemene Voorwaarden.</p>

                                <h2 style="font-weight: bold;">3. Aanbiedingen en Offertes</h2>
                                <p>3.1 Alle aanbiedingen en offertes van pcforge zijn vrijblijvend en kunnen worden
                                    herroepen totdat de overeenkomst tot stand is gekomen.</p>
                                <p>3.2 De prijzen in aanbiedingen en offertes zijn inclusief btw, tenzij anders vermeld.
                                </p>

                                <h2 style="font-weight: bold;">4. Overeenkomst en Uitvoering</h2>
                                <p>4.1 Een overeenkomst komt tot stand op het moment dat pcforge een bevestiging van de
                                    opdracht aan de klant heeft gestuurd of, bij gebreke daarvan, door de uitvoering van
                                    de dienst.</p>
                                <p>4.2 pcforge zal de diensten naar beste vermogen en met de nodige zorg uitvoeren.</p>

                                <h2 style="font-weight: bold;">5. Herroepingsrecht</h2>
                                <p>5.1 De klant heeft het recht om de overeenkomst binnen een termijn van veertien (14)
                                    dagen zonder opgave van redenen te herroepen, tenzij de overeenkomst een
                                    uitzondering vormt zoals hieronder beschreven.</p>
                                <p>5.2 De herroepingstermijn verstrijkt veertien (14) dagen na de dag waarop de klant of
                                    een door de klant aangewezen derde (niet zijnde de vervoerder) de laatste dienst
                                    heeft ontvangen.</p>
                                <p>5.3 De klant kan de herroepingstermijn niet inroepen voor:</p>
                                <ul>
                                    <li>Diensten die zijn uitgevoerd met expliciete toestemming van de klant, indien de
                                        uitvoering is begonnen voordat de herroepingstermijn is verstreken.</li>
                                    <li>Diensten die naar hun aard niet kunnen worden teruggezonden of die snel kunnen
                                        verouderen, zoals gepersonaliseerde of op maat gemaakte diensten.</li>
                                </ul>

                                <h2 style="font-weight: bold;">6. Annulering en Wijzigingen</h2>
                                <p>6.1 Annulering van een opdracht door de klant dient schriftelijk of via elektronische
                                    weg te gebeuren. Bij annulering door de klant kan pcforge recht hebben op een
                                    vergoeding voor gemaakte kosten en eventuele schade.</p>
                                <p>6.2 Wijzigingen in de opdracht door de klant kunnen extra kosten met zich meebrengen.
                                    pcforge zal de klant hierover vooraf informeren.</p>

                                <h2 style="font-weight: bold;">7. Betalingen</h2>
                                <p>7.1 Betalingen dienen te geschieden binnen de op de factuur vermelde termijn.</p>
                                <p>7.2 Bij niet-tijdige betaling is de klant van rechtswege in verzuim en is pcforge
                                    gerechtigd rente en incassokosten in rekening te brengen. Indien betaling uitblijft,
                                    kan pcforge een incassobureau inschakelen om de vordering te innen. De kosten die
                                    hiermee gemoeid zijn, komen voor rekening van de klant.</p>

                                <h2 style="font-weight: bold;">8. Aansprakelijkheid</h2>
                                <p>8.1 pcforge is slechts aansprakelijk voor directe schade die het gevolg is van
                                    opzettelijk handelen of grove nalatigheid.</p>
                                <p>8.2 De aansprakelijkheid van pcforge is beperkt tot het bedrag dat door de
                                    verzekering wordt uitgekeerd, tenzij sprake is van opzettelijk handelen of grove
                                    nalatigheid.</p>

                                <h2 style="font-weight: bold;">9. Privacy en Gegevensbescherming</h2>
                                <p>9.1 pcforge verwerkt persoonsgegevens in overeenstemming met de toepasselijke wet- en
                                    regelgeving inzake gegevensbescherming.</p>
                                <p>9.2 Voor meer informatie over hoe pcforge omgaat met persoonsgegevens, verwijzen wij
                                    naar ons Privacybeleid.</p>

                                <h2 style="font-weight: bold;">10. Klachten en Geschillen</h2>
                                <p>10.1 Klachten over de geleverde diensten moeten binnen 7 dagen na ontdekking van het
                                    gebrek schriftelijk worden gemeld aan pcforge.</p>
                                <p>10.2 Geschillen die voortvloeien uit de overeenkomst zullen worden voorgelegd aan de
                                    bevoegde rechter in Nederland, tenzij de wet een andere bevoegde rechter aanwijst.
                                </p>

                                <h2 style="font-weight: bold;">11. Slotbepalingen</h2>
                                <p>11.1 pcforge behoudt zich het recht voor deze Algemene Voorwaarden te wijzigen.
                                    Wijzigingen zullen worden gepubliceerd op onze website.</p>
                                <p>11.2 Indien een bepaling van deze Algemene Voorwaarden ongeldig blijkt, blijven de
                                    overige bepalingen volledig van kracht.</p>
                                <p>11.3 Op alle overeenkomsten tussen pcforge en de klant is het Nederlands recht van
                                    toepassing.</p>
                                <span style="float:right; font-size:12px;">Laatste update: 31-07-2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    },
    data() {
        // your data here
    }
}
</script>


<style scoped>
/* Add any component-specific styles here */
</style>