<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <router-link to="/">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
                </router-link>
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Account overzicht</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Welkom terug, {{ sessionData.firstName }}
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5">
                <div class="row justify-content-center">
                    <div class="col-lg-3 mb-4">
                        <!-- Include Account Navigation -->
                        <AccountNavigation :currentPath="currentPath" />
                    </div>
                    <div class="col-lg-7">
                        <div class="card text-white bg-primary mb-3">
                            <div class="card-body">
                                <h4 class="card-title">Account overzicht</h4>
                                <p class="card-text">
                                    Hier vind je alles wat je nodig hebt om je account te beheren.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AppHeader from '@/components/app/AppHeader.vue';
import AccountNavigation from '@/components/account/nav/AccountNavigation.vue';
import { sessionData } from '@/session';

export default {
    components: {
        AppHeader,
        
        AccountNavigation
    },
    setup() {
        const currentPath = 'account/overzicht'; // Set the current path for the navigation

        return {
            sessionData,
            currentPath
        };
    }
}
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
