<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-white py-3 mb-5">
    <div class="container px-5">
      <!-- Container for Hamburger Icon and Text -->
      <div class="d-flex align-items-center mb-1 mt-1">
        <!-- Hamburger Icon for Mobile -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Menu Text for Mobile -->
        <span class="navbar-text d-lg-none ms-2" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          Menu
        </span>
      </div>

      <!-- Navigation Links -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mb-2 mb-lg-0 small fw-bolder me-auto">
          <li v-for="(item, path) in navItems" :key="path" class="nav-item">
            <router-link :to="path" class="nav-link" :class="{ active: isActive(path) }">
              <i :class="`fa ${item.icon}`" aria-hidden="true"></i> {{ item.label }}
            </router-link>
          </li>
        </ul>
        <!-- User Account Menu -->
        <ul class="navbar-nav mb-2 mb-lg-0 ms-auto">
          <li class="nav-item dropdown" style="margin-right: 20px;">
            <a class="nav-link" :class="{ active: isAccountPage }" data-bs-toggle="dropdown" href="#" role="button"
              aria-haspopup="true" aria-expanded="false">
              <i
                :class="loading ? 'fa-solid fa-spinner fa-spin' : (sessionData.isLoggedIn ? 'fa-solid fa-user-check' : 'fa-solid fa-user-xmark')"></i>
              {{ loading ? 'Laden...' : (sessionData.isLoggedIn ? sessionData.firstName : 'Account') }}
            </a>
            <div class="dropdown-menu">
              <template v-if="sessionData.isLoggedIn">
                <!-- User Account Menu Items -->
                <span class="dropdown-item-text or-dd-item">
                  <b class="text-gradient">{{ sessionData.fullName }}</b><br />
                  Je bent ingelogd.
                </span>
                <router-link class="dropdown-item" :to="urls.accountSummary">
                  <i class="fa-solid fa-user"></i> &nbsp; Account overzicht
                </router-link>
                <router-link class="dropdown-item" :to="urls.accountOrders">
                  <i class="fa-solid fa-box-archive"></i> &nbsp; Orders
                </router-link>
                <router-link class="dropdown-item" :to="urls.accountSubscription">
                  <i class="fa-solid fa-id-card"></i> &nbsp; Lidmaatschap
                </router-link>
                <router-link class="dropdown-item" :to="urls.accountPersonalData">
                  <i class="fa-solid fa-gear"></i> &nbsp; Jouw gegevens
                </router-link>
                <a class="dropdown-item" href="/" @click.prevent="logout">
                  <i class="fa-solid fa-right-from-bracket"></i> &nbsp; Uitloggen
                </a>
              </template>
              <template v-else-if="!loading">
                <!-- Login Menu Items -->
                <span class="dropdown-item-text or-dd-item">
                  <b class="text-gradient">INLOGGEN.</b><br />
                  Je bent niet ingelogd.
                </span>
                <router-link to="/account/auth/login" class="or-dd-item">
                  <button type="button" class="btn btn-primary">Inloggen</button>
                </router-link>
                <span class="dropdown-item-text or-dd-item">
                  Geen account? <router-link :to="urls.authRegister">Registreer</router-link> je hier.
                </span>
              </template>
            </div>
          </li>
          <router-link to="/lidmaatschap">
            <button type="button" class="btn btn-warning mt-1 mb-1">Lidmaatschap</button>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>


<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { sessionData, destroySession } from '@/session'; // Import the session and destroy function

export default {
  name: 'AppHeader',
  setup() {
    const router = useRouter();

    const navItems = ref({
      '/': { icon: 'fa-home', label: 'Home' },
      '/diensten': { icon: 'fa-hands-helping', label: 'Diensten' },
      '/over-ons': { icon: 'fa-info-circle', label: 'Over ons' },
      '/contact': { icon: 'fa-phone-volume', label: 'Contact' },
    });

    const urls = ref({
      accountSummary: '/account/overzicht',
      accountOrders: '/account/orders',
      accountSubscription: '/account/lidmaatschap',
      accountPersonalData: '/account/persoonlijke-gegevens',
      authLogin: '/account/auth/inloggen',
      authRegister: '/account/auth/registreren',
    });

    const loading = ref(false);

    async function logout() {
      try {
        loading.value = true;
        await destroySession(); // Call the session destroy function
        router.push("/");
      } catch (error) {
        console.error('Error during logout:', error);
      } finally {
        loading.value = false;
      }
    }

    function isActive(path) {
      return router.currentRoute.value.path === path;
    }

    const isAccountPage = computed(() => {
      return [
        urls.value.accountSummary,
        urls.value.accountOrders,
        urls.value.accountSubscription,
        urls.value.accountPersonalData
      ].includes(router.currentRoute.value.path);
    });

    return {
      navItems,
      urls,
      sessionData, // Bind session data directly
      loading,
      isActive,
      isAccountPage,
      logout,
    };
  },
}
</script>

<style scoped>
.navbar-nav .nav-link.active {
  font-weight: bold;
}
</style>
