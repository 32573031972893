// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { sessionData } from '../session.js'; 
import HomePage from '../views/home/HomePage.vue';
import DienstenPage from '../views/diensten/DienstenPage.vue';
import OverOnsPage from '@/views/over-ons/OverOnsPage.vue';
import ContactPage from '@/views/contact/ContactPage.vue';
import VoorwaardenPage from '@/views/policies/VoorwaardenPage.vue';
import PrivacyPage from '@/views/policies/PrivacyPage.vue';
import LidmaatschapPage from '@/views/lidmaatschap/LidmaatschapPage.vue';

// Account authentication
import LoginPage from '@/views/account/auth/LoginPage.vue';
import RegisterPage from '@/views/account/auth/RegisterPage.vue';

// Account panel
import AccountOverzicht from '@/views/account/overzicht/AccountOverzicht.vue';
import AccountOrders from '@/views/account/orders/AccountOrders.vue';
import AccountLidmaatschap from '@/views/account/lidmaatschap/AccountLidmaatschap.vue';
import AccountGegevens from '@/views/account/gegevens/AccountGegevens.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { title: 'pcforge — Dé tech specialist - Computer, smartphone en meer - Voor al je problemen' }
  },
  {
    path: '/diensten',
    name: 'Diensten',
    component: DienstenPage,
    meta: { title: 'pcforge — Diensten die pcforge aanbied' }
  },
  {
    path: '/over-ons',
    name: 'Over ons',
    component: OverOnsPage,
    meta: { title: 'pcforge — Het verhaal achter pcforge' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage,
    meta: { title: 'pcforge — Neem contact op met pcforge' }
  },
  {
    path: '/voorwaarden',
    name: 'Voorwaarden',
    component: VoorwaardenPage,
    meta: { title: 'pcforge — Algemene voorwaarden' }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: PrivacyPage,
    meta: { title: 'pcforge — Privacy' }
  },
  {
    path: '/lidmaatschap',
    name: 'Lidmaatschap',
    component: LidmaatschapPage,
    meta: { title: 'pcforge — Lidmaatschap' }
  },
  {
    path: '/account/auth/login',
    name: 'Login',
    component: LoginPage,
    meta: { title: 'pcforge — Inloggen bij pcforge' },
    beforeEnter: async (to, from, next) => {
      try {
        const response = await fetch('https://api.pcforge.nl/v1/auth/user/verify', {
          method: 'GET',
          credentials: 'include'
        });
  
        if (response.ok && response.status === 200) { // Check both response status and code
          const userData = await response.json();
          if (userData.status === 'success') {
            // User is already logged in
            next('/?r=already_logged_in'); 
            return;
          }
        } // Todo: handle response error and prevent the entire page from being loaded if it requires a session
      } catch (error) {
        console.error('Error checking session status:', error);
      }
      next(); // Allow navigation to the login page
    }
  },
  {
    path: '/account/auth/registreren',
    name: 'Registreren',
    component: RegisterPage,
    meta: { title: 'pcforge - Account aanmaken bij pcforge' },
    beforeEnter: async (to, from, next) => {
      try {
        const response = await fetch('https://api.pcforge.nl/v1/auth/user/verify', {
          method: 'GET',
          credentials: 'include'
        });
  
        if (response.ok && response.status === 200) { // Check both response status and code
          const userData = await response.json();
          if (userData.status === 'success') {
            // User is already logged in
            next('/account/overzicht'); 
            return;
          }
        } // Todo: handle response error and prevent the entire page from being loaded if it requires a session
      } catch (error) {
        console.error('Error checking session status:', error);
      }
      next(); // Allow navigation to the login page
    }
  },
  {
    path: '/account/overzicht',
    name: 'Account overzicht',
    component: AccountOverzicht,
    meta: { title: 'pcforge — Account overzicht' },
    beforeEnter: (to, from, next) => {
      if (sessionData.isLoggedIn) {
        next(); 
      } else {
        next('/account/auth/login'); 
      }
    }
  },
  {
    path: '/account/orders',
    name: 'Account orders',
    component: AccountOrders,
    meta: { title: 'pcforge — Orders' },
    beforeEnter: (to, from, next) => {
      if (sessionData.isLoggedIn) {
        next(); 
      } else {
        next('/account/auth/login'); 
      }
    }
  },
  {
    path: '/account/lidmaatschap',
    name: 'Account lidmaatschap',
    component: AccountLidmaatschap,
    meta: { title: 'pcforge — Mijn lidmaatschap' },
    beforeEnter: (to, from, next) => {
      if (sessionData.isLoggedIn) {
        next(); 
      } else {
        next('/account/auth/login'); 
      }
    }
  },
  {
    path: '/account/persoonlijke-gegevens',
    name: 'Account gegevens',
    component: AccountGegevens,
    meta: { title: 'pcforge — Jouw gegevens' },
    beforeEnter: (to, from, next) => {
      if (sessionData.isLoggedIn) {
        next(); 
      } else {
        next('/account/auth/login'); 
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If savedPosition is available (e.g., when navigating back), use it
    if (savedPosition) {
      return savedPosition;
    } else {
      // Otherwise, scroll to the top
      return new Promise((resolve) => {
        // Use setTimeout to ensure the scroll happens after the DOM updates
        setTimeout(() => {
          window.scrollTo(0, 0);
          resolve({ top: 0, behavior: 'smooth' }); 
        }, 0);
      });
    }
  }
});

// Global navigation guard to set document title based on route meta
router.beforeEach((to, from, next) => {
  const title = to.meta.title || '@pcforge.nl/web';
  document.title = title;
  next();
});

export default router;
