<template>
  <div>
    <!-- Header -->
    <header class="py-5">
      <div class="container px-6 pb-5">
        <router-link to="/">
          <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
        </router-link>
        <!-- Navigation -->
        <AppHeader />
      </div>
      <div class="container px-5">
        <div class="row gx-5 align-items-center text-center">
          <h1 class="lh-1 mb-3" style="font-weight: 800;">Inloggen</h1>
          <p class="lead fw-normal text-muted mb-5">
            Welkom terug, vul je gegevens in om in te loggen
          </p>
        </div>
      </div>
    </header>
    <!-- Login Section -->
    <section class="bg-body py-5">
      <div class="container mb-5" style="margin-top: -97px;">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <!-- Display errors if any -->
                <div v-if="errors.length" class="alert alert-danger" role="alert">
                  <b><i class="fa-solid fa-circle-exclamation"></i> De volgende fout is
                    opgetreden:</b>
                  <ul style="margin: 0; padding: 0 0 0 20px;">
                    <li v-for="(error, index) in errors" :key="index" style="margin: 0; padding: 0;">{{ error }}</li>
                  </ul>
                </div>

                <!-- Login Form -->
                <form @submit.prevent="handleSubmit">
                  <h3 class="fw-bold">Inloggen</h3>
                  <div class="mb-3">
                    <label for="email" class="form-label">E-mailadres</label>
                    <input type="email" class="form-control" id="email" v-model="email" required />
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label">Wachtwoord</label>
                    <input type="password" class="form-control" id="password" v-model="password" required />
                  </div>
                  <button type="submit" class="btn btn-success">Inloggen</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useRouter } from 'vue-router';
import AppHeader from '@/components/app/AppHeader.vue';

export default {
  components: {
    AppHeader
  },
  setup() {
    // Inject session data from the root component
    const session = inject('session'); // No default value needed
    const router = useRouter();

    // State for the login form
    const email = ref('');
    const password = ref('');
    const errors = ref([]);

    // Handle form submission for login
    async function handleSubmit() {
      errors.value = []; // Clear previous errors
      try {
        const response = await fetch('https://api.pcforge.nl/v1/auth/user/dologin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: email.value,
            password: password.value
          }),
          credentials: 'include'
        });

        if (response.ok) {
          const result = await response.json();
          if (result.status === 'success') {
            // Set session data
            session.isLoggedIn = true;
            session.fullName = `${result.firstName} ${result.surname}`;
            session.firstName = `${result.firstName}`;
            session.surname = `${result.surname}`;

            // Redirect to account overview
            router.push('/account/overzicht');
          } else {
            errors.value = result.message.split(', '); // Handle server-side errors
          }
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error during login:', error);
        errors.value.push(`An error occurred during login. (${error.message})`);
      }
    }

    return {
      email,
      password,
      errors,
      handleSubmit
    };
  }
};
</script>

<style scoped></style>
