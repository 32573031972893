<template>
    <div>
        <div class="card">
            <div class="card-body">
                <div v-if="loading">Momentje, wij zoeken je gegevens erbij...</div>
                <div v-if="error">
                    <span v-if="error === 'No membership details found for the logged-in user.'">
                        Je hebt momenteel geen actief lidmaatschap. Wil je lid worden?
                        <router-link to="/account/lidmaatschap/aanvragen">Klik hier</router-link> om een lidmaatschap
                        aan te vragen.
                    </span>
                    <!-- Display general error message -->
                    <p v-else>{{ error }}</p>
                </div>
                <div v-if="membership">
                    <!-- Conditional rendering based on subscription status -->
                    <div v-if="membership.subscriptionStatus === 'active'">
                        <div class="alert alert-success" role="alert">
                            <strong><i class="fa-solid fa-square-check"></i> Je bent lid!</strong><br />
                            Je <b>{{ membership.subscriptionType }}</b> lidmaatschap is momenteel actief.<br />
                            Vervaldatum: <b>{{ formatDate(membership.subscriptionExpiryDate) }}</b>.
                        </div>
                        <div class="mt-3">
                            <router-link to="/account/lidmaatschap/beheren" class="btn btn-warning">Beheer lidmaatschap</router-link>
                        </div>
                    </div>
                    <div v-else-if="membership.subscriptionStatus === 'cancelled'">
                        <div class="alert alert-danger" role="alert">
                            <strong><i class="fa-solid fa-ban"></i> Lidmaatschap geannuleerd</strong><br />
                            Je lidmaatschap is geannuleerd. De vervaldatum is op <b>{{ formatDate(membership.subscriptionExpiryDate) }}</b>.
                        </div>
                        <div class="mt-3">
                            <router-link to="/account/lidmaatschap/beheren" class="btn btn-light">Annulering ongedaan maken</router-link>
                        </div>
                    </div>
                    <div v-else-if="membership.subscriptionStatus === 'expired'">
                        <div class="alert alert-danger" role="alert">
                            <strong><i class="fa-solid fa-triangle-exclamation"></i> Lidmaatschap verlopen</strong><br />
                            Je <b>{{ membership.subscriptionType }}</b> lidmaatschap is verlopen op <b>{{ formatDate(membership.subscriptionExpiryDate) }}</b>.
                        </div>
                        <div class="mt-3">
                            <router-link to="/account/lidmaatschap/renew" class="btn btn-light">Lidmaatschap vernieuwen</router-link>
                        </div>
                    </div>
                    <div v-else>
                        <div class="alert alert-danger" role="alert">
                            <strong><i class="fa-solid fa-triangle-exclamation"></i> We kunnen de status van je lidmaatschap niet ophalen.</strong><br />
                            Er is iets misgegaan. Neem contact op met de klantenservice als deze melding niet verdwijnt.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            membership: null,
            error: null,
            loading: true
        };
    },
    async created() {
        try {
            const response = await axios.get('https://api.pcforge.nl/v1/account/membership/fetch', { withCredentials: true });
            if (response.data.status === 'success') {
                this.membership = response.data;
            } else {
                this.error = response.data.message;
            }
        } catch (err) {
            this.error = 'An error occurred while fetching membership details.';
            console.error(err);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        formatDate(dateStr) {
            if (!dateStr) return 'N/A';
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(dateStr).toLocaleDateString(undefined, options);
        }
    }
};
</script>

<style scoped>
/* Add component-specific styles here */
</style>
