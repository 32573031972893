<template>
    <!-- Footer-->
    <footer class="bg-light py-4 mt-auto">
        <div class="container px-5">
            <div class="row align-items-center justify-content-between flex-column flex-sm-row">
                <div class="col-md-3">
                    <div class="small m-0"><img src="https://cdn.varnix.net/pcforge/assets/images/logo-black.svg"
                            width="128">
                        <p>pcforge is dé tech specialist van Nederland met een sterke focus op het aanbieden van
                            hoogwaardige IT-diensten.
                            Wij bieden een breed scala aan oplossingen, waaronder desktop assemblage, smartphone- en
                            gegevensoverdracht, ondersteuning voor zowel Windows als macOS en nog veel meer.
                        </p>
                    </div>
                </div>
                <div class="col-auto">
                    <router-link class="small" to="/voorwaarden">Voorwaarden</router-link>
                    <span class="mx-1">&middot;</span>
                    <router-link class="small" to="/privacy">Privacy</router-link>
                    <span class="mx-1">&middot;</span>
                    <router-link class="small" to="/contact">Contact</router-link>
                </div>
                <hr>
                <div class="col-auto">
                    <div class="small m-0">© 2024 pcforge</div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
// No JavaScript needed for static footer content
export default {
    name: 'AppFooter'
}
</script>

<style scoped></style>