<template>
    <div>
      <!-- Header -->
      <header class="py-5">
        <div class="container px-6 pb-5">
          <router-link to="/">
            <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
          </router-link>
          <!-- Navigation -->
          <AppHeader />
        </div>
        <div class="container px-5">
          <div class="row gx-5 align-items-center text-center">
            <h1 class="lh-1 mb-3" style="font-weight: 800;">Registreren</h1>
            <p class="lead fw-normal text-muted mb-5">
              Creëer je account en ontdek de voordelen.
            </p>
          </div>
        </div>
      </header>
      <!-- Registration Section -->
      <section class="bg-body py-5">
        <div class="container mb-5" style="margin-top: -97px;">
          <div class="row justify-content-center">
            <!-- Registration Card -->
            <div class="col-lg-6 mb-4">
              <div class="card">
                <div class="card-body">
                  <!-- Display errors if any -->
                  <div v-if="errors.length" class="alert alert-danger" role="alert">
                    <b><i class="fa-solid fa-circle-exclamation"></i> De volgende fout is opgetreden:</b>
                    <ul style="margin: 0; padding: 0 0 0 20px;">
                      <li v-for="(error, index) in errors" :key="index" style="margin: 0; padding: 0;">{{ error }}</li>
                    </ul>
                  </div>
  
                  <!-- Registration Form -->
                  <form @submit.prevent="handleSubmit">
                    <h3 class="fw-bold">Je persoonlijke gegevens</h3>
                    <h5>Vertel ons hoe je heet.</h5>
                    <div class="mb-3">
                      <label for="name" class="form-label">Voornaam</label>
                      <input type="text" class="form-control" id="name" v-model="name" required />
                    </div>
                    <div class="mb-3">
                      <label for="surname" class="form-label">Achternaam</label>
                      <input type="text" class="form-control" id="surname" v-model="surname" required />
                    </div>
                    <div class="mb-3">
                      <label>Geslacht</label>
                      <div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" id="male" value="male" v-model="gender" required />
                          <label class="form-check-label" for="male">Man</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" id="female" value="female" v-model="gender" required />
                          <label class="form-check-label" for="female">Vrouw</label>
                        </div>
                      </div>
                    </div>
                    <hr style="border: none; border-top: 1px dotted #000;">
                    <h3 class="fw-bold">Je inlog gegevens</h3>
                    <h5>Geef een e-mail en wachtwoord op.</h5>
                    <div class="mb-3">
                      <label for="email" class="form-label">E-mailadres</label>
                      <input type="email" class="form-control" id="email" v-model="email" required />
                    </div>
                    <div class="mb-3">
                      <label for="password" class="form-label">Wachtwoord</label>
                      <input type="password" class="form-control" id="password" v-model="password" @input="validatePassword" required />
                      <div id="password-requirements" class="mt-2">
                        <p id="length" :class="{ 'text-success': passwordValid.length, 'text-danger': !passwordValid.length }">
                          <i class="bi bi-x-circle" v-if="!passwordValid.length"></i>
                          <i class="bi bi-check-circle" v-if="passwordValid.length"></i> Minimaal 8 tekens zonder spaties
                        </p>
                        <p id="letter" :class="{ 'text-success': passwordValid.letter, 'text-danger': !passwordValid.letter }">
                          <i class="bi bi-x-circle" v-if="!passwordValid.letter"></i>
                          <i class="bi bi-check-circle" v-if="passwordValid.letter"></i> Minimaal 1 letter
                        </p>
                        <p id="number" :class="{ 'text-success': passwordValid.number, 'text-danger': !passwordValid.number }">
                          <i class="bi bi-x-circle" v-if="!passwordValid.number"></i>
                          <i class="bi bi-check-circle" v-if="passwordValid.number"></i> Minimaal 1 cijfer of leesteken (bijv. !@#$)
                        </p>
                      </div>
                    </div>
                    <div class="mb-3 form-check">
                      <input type="checkbox" class="form-check-input" id="tos" v-model="tos" required />
                      <label class="form-check-label" for="tos">
                        Ik ga akkoord met de <router-link to="/voorwaarden">Voorwaarden</router-link> en <router-link to="/privacy">Privacyverklaring</router-link>
                      </label>
                    </div>
                    <button type="submit" class="btn btn-success">Registreren</button>
                  </form>
                </div>
              </div>
            </div>
            <!-- Benefits Section -->
            <div class="col-lg-6 d-flex align-items-center">
              <div class="benefits">
                <h4>Voordelen van een account</h4>
                <ul>
                  <li><i class="bi bi-check-circle"></i> Sneller afrekenen met opgeslagen gegevens.</li>
                  <li><i class="bi bi-check-circle"></i> Toegang tot exclusieve aanbiedingen en kortingen.</li>
                  <li><i class="bi bi-check-circle"></i> Volg je orders en bekijk je ordergeschiedenis.</li>
                  <li><i class="bi bi-check-circle"></i> Gemakkelijk je gegevens en voorkeuren beheren.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import { ref, inject } from 'vue';
  import { useRouter } from 'vue-router';
  import AppHeader from '@/components/app/AppHeader.vue';
  
  export default {
    components: {
      AppHeader
    },
    setup() {
      const router = useRouter();
      const session = inject('session'); // Inject session data from the root component
  
      // Form state
      const name = ref('');
      const surname = ref('');
      const gender = ref('');
      const email = ref('');
      const password = ref('');
      const tos = ref(false);
      const errors = ref([]);
      const passwordValid = ref({
        length: false,
        letter: false,
        number: false
      });
  
      // Validate password requirements
      const validatePassword = () => {
        const passwordValue = password.value;
        const minLengthPattern = /.{8,}/;
        const letterPattern = /[a-zA-Z]/;
        const numberOrPunctuationPattern = /[\d\W]/;
  
        // Check length
        passwordValid.value.length = minLengthPattern.test(passwordValue);
  
        // Check for letter
        passwordValid.value.letter = letterPattern.test(passwordValue);
  
        // Check for number or punctuation
        passwordValid.value.number = numberOrPunctuationPattern.test(passwordValue);
      };
  
      // Handle form submission for registration
      const handleSubmit = async () => {
        errors.value = []; // Clear previous errors
  
        if (!passwordValid.value.length || !passwordValid.value.letter || !passwordValid.value.number) {
          errors.value.push('Het wachtwoord voldoet niet aan de vereisten.');
          return;
        }
  
        if (!tos.value) {
          errors.value.push('Je moet akkoord gaan met de voorwaarden.');
          return;
        }
  
        try {
          const response = await fetch('https://api.pcforge.nl/v1/auth/user/register', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              name: name.value,
              surname: surname.value,
              gender: gender.value,
              email: email.value,
              password: password.value
            }),
            credentials: 'include'
          });
  
          if (response.ok) {
            const result = await response.json();
            if (result.status === 'success') {
              // Attempt to log in after successful registration
              const loginResponse = await fetch('https://api.pcforge.nl/v1/auth/user/dologin', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  email: email.value,
                  password: password.value
                }),
                credentials: 'include'
              });
  
              if (loginResponse.ok) {
                const loginResult = await loginResponse.json();
                if (loginResult.status === 'success') {
                  // Set session data
                  session.isLoggedIn = true;
                  session.fullName = `${loginResult.firstName} ${loginResult.surname}`;
                  session.firstName = `${loginResult.firstName}`;
                  session.surname = `${loginResult.surname}`;
  
                  // Redirect to account overview
                  router.push('/account/overzicht');
                } else {
                  errors.value = loginResult.message.split(', '); // Handle server-side login errors
                }
              } else {
                throw new Error(`HTTP error during login! Status: ${loginResponse.status}`);
              }
            } else {
              errors.value = result.message.split(', '); // Handle server-side registration errors
            }
          } else {
            throw new Error(`HTTP error during registration! Status: ${response.status}`);
          }
        } catch (error) {
          console.error('Error during registration:', error);
          errors.value.push(`Er ging iets fout tijdens het registreren. (${error.message})`);
        }
      };
  
      return {
        name,
        surname,
        gender,
        email,
        password,
        tos,
        errors,
        passwordValid,
        handleSubmit,
        validatePassword
      };
    }
  };
  </script>
  
  <style scoped>
  /* Add any component-specific styles here */
  #password-requirements p {
    font-size: 14px;
    margin: 0;
  }
  
  #password-requirements .text-success {
    color: #2ecc71;
    font-weight: bold;
  }
  
  #password-requirements .text-danger {
    color: black !important;
  }
  
  #password-requirements i {
    margin-right: 8px;
  }
  
  .benefits {
    background-color: #ffffff;
    padding: 20px;
  }
  
  .benefits h4 {
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .benefits ul {
    list-style-type: none;
    padding: 0;
  }
  
  .benefits ul li {
    margin-bottom: 10px;
  }
  
  .benefits ul li i {
    color: #2ecc71;
    margin-right: 8px;
  }
  </style>
  