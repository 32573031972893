<template>
    <div>
        <!-- Header -->
        <header class="py-5">
            <div class="container px-6 pb-5">
                <router-link to="/">
                    <img src="https://cdn.pcforge.nl/assets/1.0/images/logo-dark.svg" width="250" />
                </router-link>
                <!-- Navigation -->
                <AppHeader />
            </div>
            <div class="container px-5">
                <div class="row gx-5 align-items-center text-center">
                    <h1 class="lh-1 mb-3" style="font-weight: 800;">Privacy</h1>
                    <p class="lead fw-normal text-muted mb-5">
                        Uw gegevens, onze zorgvuldigheid.
                    </p>
                </div>
            </div>
        </header>
        <section class="bg-body py-5">
            <div class="container mb-5" style="margin-top: -97px;">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="card">
                            <div class="card-body">
                                <h3 style="font-weight: bold;">1. Inleiding</h3>
                                <p>1.1 pcforge respecteert de privacy van haar klanten en bezoekers van de website. Dit
                                    privacybeleid legt uit hoe wij omgaan met persoonsgegevens die wij verkrijgen via
                                    onze diensten en website.</p>

                                <h3 style="font-weight: bold;">2. Verzameling van Persoonsgegevens</h3>
                                <p>2.1 Wij verzamelen en verwerken persoonsgegevens wanneer u gebruik maakt van onze
                                    diensten, onze website bezoekt, een account aanmaakt, een formulier invult of
                                    anderszins met ons communiceert.</p>
                                <p>2.2 De soorten persoonsgegevens die wij verzamelen kunnen omvatten:</p>
                                <ul>
                                    <li>Naam en contactgegevens (zoals e-mailadres, telefoonnummer, adres).</li>
                                    <li>Informatie over uw apparaat en browserinstellingen.</li>
                                    <li>Transactie-informatie en aankoopgeschiedenis.</li>
                                    <li>Andere informatie die u ons verstrekt.</li>
                                </ul>

                                <h3 style="font-weight: bold;">3. Gebruik van Persoonsgegevens</h3>
                                <p>3.1 Wij gebruiken uw persoonsgegevens voor de volgende doeleinden:</p>
                                <ul>
                                    <li>Het leveren van onze diensten en producten.</li>
                                    <li>Het verwerken van bestellingen en betalingen.</li>
                                    <li>Klantenservice en ondersteuning.</li>
                                    <li>Communicatie met u, inclusief het verstrekken van informatie over onze diensten
                                        en promoties.</li>
                                    <li>Het verbeteren van onze website en diensten.</li>
                                    <li>Het voldoen aan wettelijke verplichtingen.</li>
                                </ul>

                                <h3 style="font-weight: bold;">4. Delen van Persoonsgegevens</h3>
                                <p>4.1 Wij delen uw persoonsgegevens niet met derden, behalve in de volgende gevallen:
                                </p>
                                <ul>
                                    <li>Wanneer dit nodig is voor het uitvoeren van onze diensten, zoals het inschakelen
                                        van betalingsverwerkers en logistieke partners.</li>
                                    <li>Wanneer wij wettelijk verplicht zijn om uw gegevens te verstrekken.</li>
                                    <li>Met uw uitdrukkelijke toestemming.</li>
                                </ul>

                                <h3 style="font-weight: bold;">5. Beveiliging van Gegevens</h3>
                                <p>5.1 Wij nemen passende technische en organisatorische maatregelen om uw
                                    persoonsgegevens te beschermen tegen ongeoorloofde toegang, verlies of misbruik.</p>

                                <h3 style="font-weight: bold;">6. Bewaartermijnen</h3>
                                <p>6.1 Wij bewaren uw persoonsgegevens niet langer dan nodig is voor de doeleinden
                                    waarvoor ze zijn verzameld of zoals vereist door de wet.</p>

                                <h3 style="font-weight: bold;">7. Uw Rechten</h3>
                                <p>7.1 U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
                                    verwijderen. U kunt ook bezwaar maken tegen de verwerking van uw persoonsgegevens en
                                    verzoeken om beperking van de verwerking.</p>
                                <p>7.2 Om deze rechten uit te oefenen, kunt u contact met ons opnemen via de
                                    contactgegevens vermeld in artikel 9 van dit beleid.</p>

                                <h3 style="font-weight: bold;">8. Wijzigingen in dit Privacybeleid</h3>
                                <p>8.1 pcforge kan dit privacybeleid van tijd tot tijd wijzigen. Wij zullen belangrijke
                                    wijzigingen op onze website publiceren en, waar nodig, u informeren.</p>

                                <h3 style="font-weight: bold;">9. Contactinformatie</h3>
                                <p>9.1 Voor vragen over dit privacybeleid of de verwerking van uw persoonsgegevens kunt
                                    u contact met ons opnemen via:</p>
                                <ul>
                                    <li><strong>E-mail</strong>: <a
                                            href="mailto:privacy@pcforge.nl">privacy@pcforge.nl</a>
                                    </li>
                                </ul>
                                <span style="float:right; font-size:12px;">Laatste update: 31-07-2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import AppHeader from '@/components/app/AppHeader.vue';

export default {
    components: {
        AppHeader
    },
    data() {
        // your data here
    }
}
</script>


<style scoped>
/* Add any component-specific styles here */
</style>